import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout, Banner, SEO, SideBar, SliceZone } from '../components';
import { media } from '../utils/media';

const Page = props => {
  const { data } = props.data.prismicPage;

  return (
    <Layout>
      <SEO title={data.meta_title} description={data.meta_description} keywords={data.meta_keywords} />
      <Banner data={data} />
      <ContentWrapper>
        <Main>
          <SliceZone allSlices={data.page_content} />
        </Main>
        <SideBar />
      </ContentWrapper>
    </Layout>
  );
};

const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;

  @media ${media.tablet} {
    flex-direction: column;
  }
`;

const Main = styled.main`
  max-width: 950px;
  width: 100%;
  margin: 5rem 2rem;

  @media ${media.tablet} {
    margin: 2rem auto;
    padding: 1rem;
  }
`;

Page.propTypes = {
  data: PropTypes.shape({
    prismicPage: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query PrismicPage($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        page_title {
          text
        }
        banner_image {
          url
          alt
        }
        page_title {
          text
        }
        page_content {
          ... on PrismicPagePageContentImageGallery {
            id
            slice_type
            primary {
              name_of_the_gallery {
                html
                text
              }
            }
            items {
              gallery_image {
                alt
                url
              }
            }
          }
          ... on PrismicPagePageContentText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPagePageContentYoutubeVideo {
            id
            slice_type
            primary {
              youtube_video
            }
          }
        }
        meta_title
        meta_description
        meta_keywords
      }
    }
  }
`;

export default Page;
